.App {
  text-align: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #282c34;
  color: white;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wave-text {
  font-size: 4rem; /* Adjusted base font size */
  display: inline-block;
  transition: color 0.3s ease;
  cursor: pointer;
  margin: 0px;
}

.wave-text:hover,
.wave-text:focus {
  color: #61dafb;
}

.social-icons {
  margin-top: 20px;
}

.location {
  color: white;
  text-decoration: none;
}

.location:hover {
  color: #61dafb;
}

.social-icons a {
  color: white;
  font-size: 2rem;
  margin: 0 15px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #61dafb;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .wave-text {
    font-size: 3rem;
  }
}

@media (max-width: 800px) {
  .wave-text {
    font-size: 2.5rem;
  }
}

@media (max-width: 600px) {
  .wave-text {
    font-size: 2rem;
  }
}

@media (max-width: 400px) {
  .wave-text {
    font-size: 1.5rem;
  }
}
